<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  beforeCreate(){
    document.querySelector('body').setAttribute("style","margin:0px")
  }
}
</script>

<style lang="scss">
#app {
}
</style>
